import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';

import Blockquote from '../components/Blockquote';
import ButtonAsAnchor from '../components/Button/ButtonAsAnchor';

import { AboutQuery } from '../../graphql-types';
import Checklists from '../components/Checklists';
import SEO from '../containers/SEO/SEO';

import './o-about-section.scss';
import './o-suggested-checklists.scss';

interface Props {
  data: AboutQuery;
}

const About: FC<Props> = ({ data }) => (
  <Layout>
    <SEO title="What is CheckOps?" />

    <div className="row o-about-section">
      <section className="col col--md-10 col--lg-8">


        <h1 className="h2 u-margin-bottom">
          <div className="u-color-secondary">About </div> 
        </h1>

        <h3>
          What is CheckOps?
        </h3>

        <p>
          CheckOps is a practise of using checklists for our operational tech activities. 
        </p>

        <p>
          CheckOps.com is collection of cummunity curated checklists where each checklist is an exhaustive list of all things you need to check before every tech. activity like website development or website launch..
        </p>

        <p>
          Anyone can suggest and improve our open source checklists on github with contributions or feedbacks and make others life easy.
        </p>

        <p>
          So why depenend on others? Check how much your of your tech. work is complete or meets all standards using our interactive cheklists and share progress with others.
        </p>

        <p>
         Bonus : Bored of running throgh checklists manually ,try our free our online website checker to get automated reports from these checklists.
        </p>

        <ButtonAsAnchor
          href="https://labs.checkops.com"
          className="u-margin-top-xlarge u-width-100%@sm-down"
          target="_blank"
          rel="noopener noreferrer"
        >
          RUN FREE TEST
        </ButtonAsAnchor>
        
      </section>
    </div>
    <hr className="u-margin-ends-0 u-color-primary-900" />
    <section className="o-suggested-checklists">
      <h2 className="u-margin-bottom">Check out these checklists before you go!</h2>
      <div className="row">
        <div className="col col--md-9 col--lg-6">
          <Blockquote>
            These are Community Curated Checklists so anyone can suggest changes
            <span role="img" aria-labelledby="rocket">
              
            </span>
          </Blockquote>
        </div>
      </div>
      <Checklists items={data.suggestions.nodes} className="u-margin-top-medium" />
    </section>
  </Layout>
);

export const pageQuery = graphql`
  query About {
    suggestions: allMarkdownRemark(
      filter: { fields: { slug: { in: ["checklist-checklist", "improvement-checklist"] } } }
    ) {
      nodes {
        ...Checklist
      }
    }
  }
`;

export default About;
